import { NavigationProp, useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { View, ActivityIndicator } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { t } from "react-native-tailwindcss";
import { useToast } from "react-native-toast-notifications";
import { useQueryClient } from "react-query";
import styled from "styled-components/native";
import { useAccounts } from "../../../../context/accountsContext";
import { useError } from "../../../../context/errorContext";
import { useJob } from "../../../../hooks/useJob";
import { SetupScreensParamList } from "../../../../navigation/setup-screens";
import { getErrorMessage } from "../../../../services/api/api";
import { QueryKey } from "../../../../services/api/query";
import { setBasiqDebitAccountJob } from "../../../../services/userService";
import Button from "../../../atoms/Button";
import { ExtraLargeText } from "../../../atoms/ExtraLargeText";
import { LargeText } from "../../../atoms/LargeText";
import { MediumText } from "../../../atoms/MediumText";
import { ScreenContainer } from "../../../layout/ScreenContainer";
import { Footer } from "../../../molecules/Footer";
import { LineTitle } from "../../../molecules/LineTitle";
import { ZeptoTerms } from "../../../molecules/ZeptoTerms";
import { JobFeedback } from "../../../organisms/JobFeedback";
import { FundingAccount } from "../../App/FundingAccount/FundingAccount";
import ClientLogo from "../../../atoms/ClientLogo";
import { appConfig } from "../../../../config/config";
import { authStyles } from "../../Auth/shared";
import { HCenterStack } from "../../../layout/HStack";
import { AccountType } from "../../../../dtos/accountTypes";

const StyledFundingAccountContentContainer = styled.View`
  margin-top: 1px;
`;

const StyledFooterContainer = styled.View`
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const FundingAccountSetupScreen = (): React.ReactElement => {
  const [polling, setPolling] = useState<boolean>(false);
  const setDebitAccountJobContext = useJob(setBasiqDebitAccountJob);
  const queryClient = useQueryClient();
  const { setCriticalError } = useError();

  const toast = useToast();
  const navigation = useNavigation<NavigationProp<SetupScreensParamList>>();
  const { accounts, isAccountsLoading } = useAccounts();
  const [fundingAccountSelected, setFundingAccountSelected] =
    useState<boolean>(false);

  useEffect(() => {
    const hasFundingAccountSet = !!accounts?.find((a) => a.activeDebitEnabled);
    setFundingAccountSelected(hasFundingAccountSet);
  }, [accounts]);

  const updateAccount = async (accountId: string) => {
    try {
      setPolling(true);
      await setDebitAccountJobContext.execute(accountId);
      queryClient.invalidateQueries(QueryKey.User);
      queryClient.invalidateQueries(QueryKey.Accounts);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } catch (error: Error & ApiError) {
      console.log(getErrorMessage(error));
      toast.show("Something went wrong", {
        type: "danger",
      });
      setCriticalError(error);
    } finally {
      setPolling(false);
    }
  };

  return (
    <ScrollView>
      <ScreenContainer center backgroundColor="#ffffff" padding={1}>
        {polling ? (
          <>
            <View style={{
              backgroundColor: appConfig.dashboardBackground,
              paddingHorizontal: 40,
              paddingVertical: 20
            }}>
              <HCenterStack style={authStyles.imageContainer}>
                <View style={{ width: "70%" }}>
                  <ClientLogo />
                </View>
              </HCenterStack>
              <LargeText center>Setting Funding Account</LargeText>

              <JobFeedback
                steps={[
                  {
                    title: "Configuring your debiting agreement",
                    context: setDebitAccountJobContext,
                  },
                ]}
                description="We are requesting permission to debit your account. This could take a minute."
              />
            </View>
          </>
        ) : (
          <>
            <View style={{
              backgroundColor: appConfig.dashboardBackground,
              paddingHorizontal: 40,
              paddingVertical: 20
            }}>
              <HCenterStack style={authStyles.imageContainer}>
                <View style={{ width: "70%" }}>
                  <ClientLogo />
                </View>
              </HCenterStack>

              <ExtraLargeText style={{ fontSize: 25, fontWeight: '400' }} center>Select Your Funding Account</ExtraLargeText>
              <MediumText style={{ textAlign: 'center' }}>Select one account below to be your funding account.</MediumText>
              <MediumText style={{ textAlign: 'center' }}>
                Your funding account will have your round-ups direct debited on Monday, Wednesday & Friday.
              </MediumText>
              <View style={{ padding: 15 }}>
                <ZeptoTerms />
              </View>
            </View>
            <StyledFundingAccountContentContainer>
              <View style={{ paddingHorizontal: 40 }}>
                {isAccountsLoading ?
                  <View style={{ justifyContent: 'center', height: (60 * (accounts?.length ?? 1)) }}>
                    <ActivityIndicator size={'large'} color={'#FFC829'} />
                  </View>
                  :
                  <View style={{ paddingTop: 30, paddingBottom: 5 }}>
                    {accounts?.map((account, i) => (
                      <FundingAccount
                        valid={
                          [
                            AccountType.transaction,
                            AccountType.savings
                          ].includes(
                            account.accountType
                          )}
                        account={account}
                        key={i.toString()}
                        checked={account.activeDebitEnabled}
                        updateAccount={updateAccount}
                      />
                    ))}
                  </View>
                }

                <View style={styles.buttonContainer}>
                  <Button
                    label="Next"
                    onPress={() => navigation.navigate("FinishOnboarding")}
                    disabled={!fundingAccountSelected}
                    colour={!fundingAccountSelected ? "gray" : undefined}
                  />
                </View>
              </View>
            </StyledFundingAccountContentContainer>
            <StyledFooterContainer style={{ paddingBottom: 5 }}>
              <Footer />
            </StyledFooterContainer>
          </>
        )}
      </ScreenContainer>
    </ScrollView>

  );
};

const styles = {
  buttonContainer: [t.pL0, t.pR0],
};
