import React, { useEffect, useState } from "react";
import { ActivityIndicator, View, Text, StyleSheet } from "react-native";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { t } from "react-native-tailwindcss";
import styled from "styled-components/native";
import { QueryKey } from "../../../../../services/api/query";

import { ScreenContainer } from "../../../../layout/ScreenContainer";
import Button from "../../../../atoms/Button";
import { MediumText } from "../../../../atoms/MediumText";
import { Footer } from "../../../../molecules/Footer";
import { HCenterStack } from "../../../../layout/HStack";
import {
  createBasiqBankJob,
  getUser,
  updateBasiqBankJob,
} from "../../../../../services/userService";
import { User } from "../../../../../dtos/user";
import { ApiError } from "../../../../../services/api/api";
import { getJob } from "../../../../../services/jobService";
import { Job, JobStatus } from "../../../../../dtos/job";
import { appConfig } from "../../../../../config/config";
import { OnboardingMessage } from "../components/OnboardingMessage";
import { Spinner } from "../../../../organisms/Spinner";
import ClientLogo from "../../../../atoms/ClientLogo";
import { authStyles } from "../../../../screens/Auth/shared";
import { ScrollView } from "react-native-gesture-handler";

const StyledFooterContainer = styled.View`
  margin-top: 50px;
`;
// In this screen we handle the error scenarios
export const BasiqJobStatusSuccess = (): React.ReactElement => {
  const [reconsentRunning, setReconsentRunningState] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const { mutate, data: reconsentJobData } = useMutation(updateBasiqBankJob, {
    mutationKey: [QueryKey.CreateReconsentBasiqBankJob],
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.User);
      queryClient.invalidateQueries(QueryKey.CreateBasiqBankJob);
      setReconsentRunningState(true);
    },
  });

  const { data: jobData } = useQuery<Job | null, ApiError>({
    queryKey: [QueryKey.CreateBasiqBankJob],
    queryFn: () => createBasiqBankJob(),
  });

  const { data: jobStatusData } = useQuery<Job | null, ApiError>({
    queryKey: [QueryKey.Jobs],
    queryFn: () =>
      getJob(reconsentJobData ? reconsentJobData._id : jobData?._id),
    enabled: !!jobData || !!reconsentJobData,
    refetchInterval: (data) => {
      return data && data?.status === JobStatus.Pending ? 1000 : false;
    },
  });

  const { data: user, isLoading: isUserConcentLinkLoading } = useQuery<
    User | null,
    ApiError
  >({
    queryKey: [QueryKey.User],
    queryFn: () => getUser(),
    enabled: !!jobStatusData && jobStatusData.status === JobStatus.Success,
  });

  useEffect(() => {
    if (jobStatusData && jobStatusData?.status !== JobStatus.Pending) {
      setReconsentRunningState(false);
    }
  }, [jobStatusData, user]);

  if (isUserConcentLinkLoading) {
    return <Spinner />;
  }

  return user ? (
    <ScrollView style={{ backgroundColor: appConfig.dashboardBackground, }}>
      <ScreenContainer center>
        <HCenterStack style={authStyles.imageContainer}>
          <View style={{ width: "70%" }}>
            <ClientLogo />
          </View>
        </HCenterStack>

        <OnboardingMessage user={user} />

        {((user && !user.consent) || reconsentRunning) && (
          <HCenterStack>
            <ActivityIndicator size="large" color={appConfig.primaryColour} />
          </HCenterStack>
        )}

        <View style={styles.buttonContainer}>
          <Button
            colour={
              (user && !user.consent) || reconsentRunning ? "gray" : undefined
            }
            label="Easy! Lets Begin"
            disabled={isUserConcentLinkLoading || reconsentRunning}
            onPress={async () => {
              if (user?.externalUserId && !user.isConsent) {
                window.open(user.consent, "_self");
              }
            }}
          />
        </View>

        {user && user.consent && (
          <View style={rnStyles.reconsentLinkContainer}>
            <HCenterStack>
              <MediumText center>
                Note: If your consent flow fails then click{" "}
                <Text
                  style={rnStyles.linkButton}
                  onPress={() => {
                    mutate({
                      consentRequestType:
                        user &&
                          !user.onboardingComplete &&
                          Array.isArray(user.basiqJobStatus) &&
                          user.basiqJobStatus.length === 0
                          ? "consent_expired"
                          : "consent_failed",
                    });
                  }}
                >
                  here
                </Text>
              </MediumText>
            </HCenterStack>
          </View>
        )}
      </ScreenContainer>
      <StyledFooterContainer>
        <Footer />
      </StyledFooterContainer>
    </ScrollView>
  ) : (
    <></>
  );
};

const rnStyles = StyleSheet.create({
  reconsentLinkContainer: {
    marginTop: 20,
  },
  linkButton: {
    fontWeight: "700",
    textDecorationLine: "underline",
    color: '#FFC829'
  },
});

const styles = {
  buttonContainer: [{ marginTop: 50, paddingHorizontal: 20 }],
};
