import Constants from "expo-constants";
import React from "react";
import { Text, Linking, View } from "react-native";
import { t } from "react-native-tailwindcss";
import Button from "../../../atoms/Button";
import { ScreenContainer } from "../../../layout/ScreenContainer";

export const HelpScreen = (): React.ReactElement => {
  return (
    <ScreenContainer>
      <Text style={t.mB5}>
        {Constants.manifest?.extra?.company ?? "This application"} uses Sipora,
        Australia's leading round-up as a service platform.
      </Text>

      <Text style={t.mB5}>
        To report a bug, or if you need any help, please get in touch at
        hello@sipora.com.au.
      </Text>

      <View style={styles.buttonContainer}>
        <Button
          label="Contact support"
          onPress={() => Linking.openURL("mailto:hello@sipora.com.au")}
        />
      </View>
    </ScreenContainer>
  );
};

const styles = {
  buttonContainer: [{ width: 200 }, t.mLAuto, t.mRAuto],
};
