import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { t } from "react-native-tailwindcss";
import { useQueryClient } from "react-query";
import { QueryKey } from "../../../../services/api/query";
import Button from "../../../atoms/Button";
import { ExtraLargeText } from "../../../atoms/ExtraLargeText";
import { MediumText } from "../../../atoms/MediumText";
import { HCenterStack } from "../../../layout/HStack";
import { ScreenContainer } from "../../../layout/ScreenContainer";
import { Footer } from "../../../molecules/Footer";
import { useError } from "../../../../context/errorContext";
import ClientLogo from "../../../atoms/ClientLogo";
import {
  convertErrorCodeToErrorMessage,
  ErrorCode,
} from "../../../../services/api/api";

const StyledFooterContainer = styled.View`
  margin-top: 50px;
`;

export const CriticalErrorScreen = (): React.ReactElement => {
  const queryClient = useQueryClient();
  const { criticalError, setCriticalError } = useError();
  const errorMessage = convertErrorCodeToErrorMessage(
    criticalError?.response?.data?.code,
  );
  const buttonText =
    criticalError?.response?.data?.code === ErrorCode.encryptionKeyError
      ? "Reconnect"
      : "Reload";
  return (
    <ScreenContainer center>
      <HCenterStack>
        <View style={{ height: 80, width: "100%", paddingVertical: 10 }}>
          <ClientLogo />
        </View>
      </HCenterStack>
      <HCenterStack>
        <ExtraLargeText center>Something went wrong</ExtraLargeText>
      </HCenterStack>

      <HCenterStack>
        <MediumText>{errorMessage}</MediumText>
      </HCenterStack>
      <HCenterStack>
        <MediumText>Please, reload the page or logout and login the user again.</MediumText>
      </HCenterStack>

      <View style={styles.buttonContainer}>
        <Button
          onPress={() => {
            // refetch data
            queryClient.invalidateQueries(QueryKey.User);
            queryClient.invalidateQueries(QueryKey.Accounts);
            // clear error
            setCriticalError(null);
            window.location.reload();
          }}
          label={buttonText}
        />
      </View>

      <StyledFooterContainer>
        <Footer />
      </StyledFooterContainer>
    </ScreenContainer>
  );
};

const styles = {
  buttonContainer: [{ width: 200, marginTop: 20 }, t.mLAuto, t.mRAuto],
};
