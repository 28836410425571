import React from "react";
import { Dimensions, View } from "react-native";
import { t } from "react-native-tailwindcss";
import { useQueryClient } from "react-query";
import { useToast } from "react-native-toast-notifications";
import styled from "styled-components/native";
import { ScreenContainer } from "../../../layout/ScreenContainer";
import Button from "../../../atoms/Button";
import { HCenterStack, HStack } from "../../../layout/HStack";
import { MediumText } from "../../../atoms/MediumText";
import {
  basiqTransactionOccurrence,
  finishOnboarding,
} from "../../../../services/userService";
import { QueryKey } from "../../../../services/api/query";
import { getErrorMessage } from "../../../../services/api/api";
import Card from "../../../atoms/Card";
import ClientLogo from "../../../atoms/ClientLogo";
import { appConfig } from "../../../../config/config";
import { WEB_HEADER_HEIGHT } from "../../../../web-helper";
import { authStyles } from "../../Auth/shared";

const StyledButtonsContainer = styled(HStack)`
  justify-content: space-between;
`;

const StyledHalfWidthButtonContainer = styled(HStack)`
  width: 49%;
`;

export const FinishOnboardingScreen = (): React.ReactElement => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const finishOnboardingFlow = async (syncBeginningOfMonth: boolean) => {
    try {
      await finishOnboarding();
      await basiqTransactionOccurrence(syncBeginningOfMonth);
      // queryClient.invalidateQueries(QueryKey.User);

      window.location.reload();

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } catch (error: Error & ApiError) {
      console.log(getErrorMessage(error));
      toast.show("Something went wrong", {
        type: "danger",
      });
    }
  };

  const { height } = Dimensions.get("window");

  return (
    <ScreenContainer
      backgroundColor={appConfig.dashboardBackground}
      style={{ height }}
    >
      <Card style={styles.card}>
        <HCenterStack style={authStyles.imageContainer}>
          <View style={{ width: "70%" }}>
            <ClientLogo />
          </View>
        </HCenterStack>
        <HCenterStack>
          <MediumText center style={[t.fontBold, { paddingTop: 30 }]}>
            Thank you
          </MediumText>
        </HCenterStack>
        <MediumText center>Onboarding has been complete:</MediumText>
        <MediumText center>
          Would you like to start your roundups as of the 1st of this month?
        </MediumText>
        <StyledButtonsContainer style={[t.pT4, { paddingBottom: 100 }]}>
          <StyledHalfWidthButtonContainer>
            <Button
              label="YES"
              onPress={async () => {
                await finishOnboardingFlow(true);
              }}
            />
          </StyledHalfWidthButtonContainer>
          <StyledHalfWidthButtonContainer>
            <Button
              label="NO"
              onPress={async () => {
                await finishOnboardingFlow(false);
              }}
            />
          </StyledHalfWidthButtonContainer>
        </StyledButtonsContainer>
      </Card>
    </ScreenContainer>
  );
};

const styles = {
  buttonContainer: [{ width: 200, marginTop: 20 }, t.mLAuto, t.mRAuto],
  card: [t.flex, t.flexCol, t.p5, t.mB6, t.bgWhite, t.shadowXl],
  button: [{ borderRadius: 20 }],
};
